@use '@angular/material' as mat;
@use 'fonts';

/*  Theming  */
$my-headline-level: mat.define-typography-level(
  $font-family: ForzaBoldWeb,
  $font-size: 48px,
);

// Specify "Gotham" as the default font family for all levels.
$my-typography: mat.define-typography-config(
  $font-family: '"GothamWeb", Helvetica, Arial, sans-serif',
  $headline: $my-headline-level,
);

@include mat.core($my-typography);

/* For use in src/lib/core/theming/_palette.scss */
// Palette Generated at this website:
// http://mcg.mbitson.com/#!?primaryblack=%2323282b&primarywhite=%23f2f3f4&primaryblue=%23008cc3&secondarygreen=%2300a846&secondaryyellow=%23f0b428&secondaryred=%23b42846&themename=semattheme
$md-primaryblack: (
  50: #e5e5e6,
  100: #bdbfbf,
  200: #919495,
  300: #65696b,
  400: #44484b,
  500: #23282b,
  600: #1f2426,
  700: #1a1e20,
  800: #15181a,
  900: #0c0f10,
  A100: #56c7ff,
  A200: #23b6ff,
  A400: #009fef,
  A700: #008ed5,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$md-primarywhite: (
  50: #fdfefe,
  100: #fbfbfc,
  200: #f9f9fa,
  300: #f6f7f7,
  400: #f4f5f6,
  500: #f2f3f4,
  600: #f0f1f3,
  700: #eeeff1,
  800: #ecedef,
  900: #e8eaec,
  A100: #ffffff,
  A200: #ffffff,
  A400: #ffffff,
  A700: #ffffff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$md-primaryblue: (
  50: #e0f1f8,
  100: #b3dded,
  200: #80c6e1,
  300: #4dafd5,
  400: #269dcc,
  500: #008cc3,
  600: #0084bd,
  700: #0079b5,
  800: #006fae,
  900: #005ca1,
  A100: #cbe6ff,
  A200: #98cdff,
  A400: #65b4ff,
  A700: #4ca7ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$md-secondarygreen: (
  50: #e0f5e9,
  100: #b3e5c8,
  200: #80d4a3,
  300: #4dc27e,
  400: #26b562,
  500: #00a846,
  600: #00a03f,
  700: #009737,
  800: #008d2f,
  900: #007d20,
  A100: #aaffb9,
  A200: #77ff8e,
  A400: #44ff64,
  A700: #2aff4f,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$md-secondaryyellow: (
  50: #fdf6e5,
  100: #fbe9bf,
  200: #f8da94,
  300: #f5cb69,
  400: #f2bf48,
  500: #f0b428,
  600: #eead24,
  700: #eca41e,
  800: #e99c18,
  900: #e58c0f,
  A100: #ffffff,
  A200: #fff0de,
  A400: #ffd9ab,
  A700: #ffce91,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$md-secondaryred: (
  50: #f6e5e9,
  100: #e9bfc8,
  200: #da94a3,
  300: #cb697e,
  400: #bf4862,
  500: #b42846,
  600: #ad243f,
  700: #a41e37,
  800: #9c182f,
  900: #8c0f20,
  A100: #ffbec5,
  A200: #ff8b97,
  A400: #ff586a,
  A700: #ff3e53,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #ffffff,
  ),
);

$my-primary: mat.define-palette($md-primaryblack, 500);
$my-accent: mat.define-palette($md-secondarygreen, A200, A100, A400);
$my-warn: mat.define-palette($md-secondaryred, A200, A100, A400);

$my-theme: mat.define-light-theme(
  (
    color: (
      primary: $my-primary,
      accent: $my-accent,
      warn: $my-warn,
    ),
    typography: $my-typography,
  )
);

@include mat.all-component-themes($my-theme);

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}
body {
  margin: 0;
  // font-family: 'Gotham SSm A', 'Gotham SSm B', 'Gotham', Helvetica, Arial,
  // sans-serif;
}

// Custom styles in a bad global place.  Figure out how to get palette colors from mixins inside components.
.red-background {
  background-color: mat.get-color-from-palette($my-warn, 500) !important;
}
